<script lang="ts" setup>
interface Props {
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
  size: 1,
})
</script>

<template>
    <svg
        :width="21 * props.size"
        :height="21 * props.size"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22.3105 36.375H27.0605V22.125H22.3105V36.375ZM24.6855 17.375C25.3585 17.375 25.9229 17.147 26.3789 16.691C26.8349 16.235 27.0621 15.6713 27.0605 15C27.0605 14.3271 26.8325 13.7626 26.3765 13.3066C25.9205 12.8506 25.3569 12.6234 24.6855 12.625C24.0126 12.625 23.4482 12.853 22.9922 13.309C22.5362 13.765 22.309 14.3287 22.3105 15C22.3105 15.6729 22.5385 16.2374 22.9945 16.6934C23.4505 17.1494 24.0142 17.3766 24.6855 17.375ZM24.6855 48.25C21.4001 48.25 18.3126 47.6262 15.423 46.3785C12.5335 45.1308 10.0199 43.439 7.88242 41.3031C5.74492 39.1656 4.05313 36.6521 2.80705 33.7625C1.56096 30.8729 0.93713 27.7854 0.935547 24.5C0.935547 21.2146 1.55938 18.1271 2.80705 15.2375C4.05471 12.3479 5.74651 9.83438 7.88242 7.69688C10.0199 5.55938 12.5335 3.86758 15.423 2.6215C18.3126 1.37542 21.4001 0.751583 24.6855 0.75C27.971 0.75 31.0585 1.37383 33.948 2.6215C36.8376 3.86917 39.3512 5.56096 41.4887 7.69688C43.6262 9.83438 45.3188 12.3479 46.5664 15.2375C47.8141 18.1271 48.4371 21.2146 48.4355 24.5C48.4355 27.7854 47.8117 30.8729 46.564 33.7625C45.3164 36.6521 43.6246 39.1656 41.4887 41.3031C39.3512 43.4406 36.8376 45.1332 33.948 46.3809C31.0585 47.6285 27.971 48.2516 24.6855 48.25ZM24.6855 43.5C29.9897 43.5 34.4824 41.6594 38.1637 37.9781C41.8449 34.2969 43.6855 29.8042 43.6855 24.5C43.6855 19.1958 41.8449 14.7031 38.1637 11.0219C34.4824 7.34062 29.9897 5.5 24.6855 5.5C19.3814 5.5 14.8887 7.34062 11.2074 11.0219C7.52617 14.7031 5.68555 19.1958 5.68555 24.5C5.68555 29.8042 7.52617 34.2969 11.2074 37.9781C14.8887 41.6594 19.3814 43.5 24.6855 43.5Z"
            fill="currentColor"
        />
    </svg>
</template>

<style scoped></style>
