<script lang="ts" setup>
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue'
import type { CoursesCategoryWithCounter } from '~~/layers/api/models/Courses'

interface Props {
    category: CoursesCategoryWithCounter;
}

const props = defineProps<Props>()

const rb = useRouteBuilder()

const infoIcon = ref(null)
const infoTooltip = ref(null)

const isInfoIconHovered = useElementHover(infoIcon, {
  delayEnter: 250,
  delayLeave: 250,
})

const { floatingStyles } = useFloating(infoIcon, infoTooltip, {
  placement: 'bottom',
  whileElementsMounted: autoUpdate,
  middleware: [offset(10), flip(), shift()],
})

const countSuffix = computed(() => {
  const count = props.category.courses_count
  const lastDigit = count % 10

  if (count >= 5 && count <= 20) {
    return 'ов'
  }

  if (lastDigit === 1) {
    return ''
  }

  if (lastDigit >= 2 && lastDigit <= 4) {
    return 'а'
  }

  return 'ов'
})

const courseCountComment = computed(() => {
  const count = props.category.courses_count

  if (count === 0) {
    return 'Нет курсов'
  }

  return `${count} курс${countSuffix.value}`
})
</script>

<template>
    <NuxtLink :to="rb.toCourseCategory(props.category)" class="group">
        <figure
            class="border border-uk-blue px-7 py-4 rounded-2xl transition-colors group-hover:text-uk-orange flex flex-col items-start justify-between min-h-[149px] h-full"
        >
            <figcaption>
                <TextSubtitle bold>
                    {{ props.category.name }}

                    <template v-if="props.category.comment">
                        <IconInfo
                            ref="infoIcon"
                            class="text-uk-gray inline cursor-pointer shrink-0"
                        />
                    </template>
                </TextSubtitle>

                <TextCaption
                    v-if="isInfoIconHovered"
                    ref="infoTooltip"
                    :style="floatingStyles"
                    class="text-black bg-white border border-uk-blue rounded-2xl px-5 py-2 w-fit absolute max-w-sm"
                >
                    {{ props.category.comment }}
                </TextCaption>
            </figcaption>

            <TextBody class="flex items-center gap-1 text-uk-blue">
                {{ courseCountComment }}

                <IconArrow
                    v-if="props.category.courses_count > 0"
                    :size="0.6"
                    class="mt-1"
                />
            </TextBody>
        </figure>
    </NuxtLink>
</template>

<style scoped></style>
