<script lang="ts" setup>
const { courseService } = useApi()

const { data, pending, error } = useLazyAsyncData(() =>
  courseService.getCategoriesWithCounters()
)

const categories = computed(() => data.value?.data ?? [])
</script>

<template>
    <div class="flex flex-col items-start gap-12 xl:py-20">
        <TextHeading :level="2">Категории обучения</TextHeading>

        <PreloadContainer
            :pending="pending"
            :error="error"
            error-message="Не удалось загрузить категории обучения, попробуйте позже"
        >
            <div
                class="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 items-stretch"
            >
                <CoursesGridCard
                    v-for="category in categories"
                    :key="category.slug"
                    :category="category"
                />
            </div>
        </PreloadContainer>

        <LinkColored to="/courses" variant="primary-border">
            Перейти ко всем курсам
        </LinkColored>
    </div>
</template>

<style scoped></style>
